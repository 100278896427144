@import '~materialize-css/sass/components/color-variables';
@import '~materialize-css/sass/components/variables';
@import './vars';

#dropdown-nav.dropdown-content {
    height: auto !important;
    li {
        padding: 1em;
    }
}

.language-list {
    list-style: none;
    margin-right: auto;
}

.lang,
.lang-active {
    float: left;
}

.nav-list {
    list-style: none;
    margin-left: auto;
}
.nav,
.nav-active {
    float: right;
}
.nav,
.lang {
    padding: 0 1.4em;
}
.nav-active,
.lang-active {
    padding: 0 1.4em;
    button {
        font-weight: bold;
        //border-bottom: 2px solid $c-purple-light;
    }
}
.btn-nav,
.btn-lang {
    background-color: transparent;
    color: #222;
    text-decoration: none;
    border: none;
    height: 2em;
    line-height: 2em;
    outline: none;
    cursor: pointer;
    text-transform: capitalize;
}
.btn-nav a,
.btn-lang a {
    text-decoration: none;
    color: $c-purple-light;
    outline: none;
}
button:disabled,
.btn-nav:hover,
.btn-nav a:hover,
.btn-nav:active,
.btn-nav a:active,
.btn-nav:focus,
.btn-nav a:focus,
.nav-active.btn-nav,
.nav-active.btn-nav a,
.btn-lang:hover,
.btn-lang a:hover,
.btn-lang:active,
.btn-lang a:active .btn-lang:focus,
.btn-lang a:focus .lang-active.btn-nav,
.lang-active.btn-nav a {
    background-color: transparent;
    outline: none;
    color: $c-dark;
}

/* ADDING AN ICON TO THE LANGUAGE MENU, it needs something, not sure what */
.lang-active::before,
.lang::before {
    font-family: "Font Awesome 5 Pro";
    content: '\f1ab';
    color: $c-purple-light;
}
.lang::before {
    color: #ccc;
}

.language-list > ul,
.nav-list > ul {
    margin: 0;
}
.topbar {
    display: block;
    min-width: 100%;
}
.col-light {
    background-color: $c-light;
}

.nav-sub {
    //background-color: rgba($color: $c-purple-dark, $alpha: 0.7);
    background: map-get($gradients, nlm);
    img {
        height: 100%;
        padding: 1em;
        max-height: 100px;
    }
}
.dropdown-btn {
    &.btn {
        margin: 0;
        background-color: transparent;
        color: $c-dark;
        &:hover,
        &:focus {
            background-color: transparent;
            box-shadow: none;
            color: $c-purple-light;
        }
    }
}

.search-form-icon {
    font-size: 4em;
    color: $c-light;
}

@media #{$medium-and-down} {
    .search-form-icon {
        font-size: 2.2em;
    }
}
