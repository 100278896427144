// .wrapper > .inner-page {
//     margin: 0 auto;
//     padding-bottom: 0.01em;
//     padding: 20px 10% 10px 10%;
//     font-size: 1.4em;
//     width: 100%;
//     max-width: 58.5em;
// }
// .wrapper.style1 > .inner {
//     background-color: #efefef;
// }
// section.special,
// article.special,
// header.special {
//     text-align: left;
// }

// .spotlights-page {
//     text-align: center;
//     display: block;
//     font-size: 1.2em;
//     padding: 1em 3em;
// }

.display-block {
    display: block !important;
}

.display-none {
    display: none;
}

.z {
    z-index: 200001;
    background: transparent;
    bottom: 0;
    right: 10px;
    position: absolute;
}

.yo {
    color: 'red';
}

.blinky {
    animation: blinker 1s linear infinite;
    text-transform: uppercase;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.wp-video,
video.wp-video-shortcode {
    width: 100% !important;
    height: 100% !important;
}

.wp-video,
video.wp-video-shortcode {
    max-width: 100% !important;
}
video.wp-video-shortcode {
    position: relative;
}

/* MEDIA QUERIES
breakpoints: 1680, 1280, 980, 736, 480
*/
@media screen and (max-width: 1680px) {
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 980px) {
}

@media screen and (max-width: 736px) {
    #banner header {
        padding: 3em;
    }
}

@media screen and (max-width: 480px) {
    button {
        padding-left: 10px;
        padding-right: 10px;
    }

    #banner header {
        padding: 0;
    }
}
