.spotlights.col1 > section {
    width: 100%;
}

.spotlights.col1 > section:nth-child(odd) {
    border-right: none;
}

.image {
    border-radius: 4px;
    border: 0;
    display: inline-block;
    position: relative;
    margin: 1em auto;

    img {
        border-radius: 4px;
        display: block;
        //width: 100%;
        max-width: 100%;
    }
}
.posts-col {
    .image,
    .wp-video {
        margin: 2em auto;
        //padding: 0 0 0 1em;
    }
}
