@import '~materialize-css/sass/components/color-variables';
@import '~materialize-css/sass/components/variables';
@import './vars';

.list-stats {
    font-size: 1.2em;
    list-style: none;
    border-left: 1px solid #999;
    margin-bottom: 3em;
    margin-left: 3em;
    li {
        p {
            padding: 8px 0 4px 8px;
            //color: $c-dark;
            margin: 0 0 1.2em 0;
            em {
                font-weight: normal;
                font-style: normal;
                font-family: 'Merriweather';
                color: $c-light-alt;
            }
        }
    }
}
.stats-row {
    background-color: rgba($color: $c-dark, $alpha: 1);
    color: rgba($color: $c-light-alt, $alpha: 0.8);
    border-top: 0.3em solid rgba($color: $c-purple, $alpha: 0.8);
}

@media #{$medium-and-down} {
    .list-stats {
        margin-left: 1em;
    }
}
