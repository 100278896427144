// `linear-gradient(to bottom,
//     rgba(${this.rgbLight},0.1) 70%,
//     rgba(${this.rgbLight},1.0) 100%),
@import './vars';

#banner {
    background-size: cover;
    margin-top: 0;
    font-family: 'Noto Serif', serif;
    padding: 3em;
    background: map-get($gradients, nlm);

    color: #fff;
    header {
        background-color: transparent;
        padding: 5em 0 2em 0;
    }
    h3 {
        color: #fff;
        font-size: 2em;
    }
}
.hold-image img {
    width: 100%;
}
