@import './vars';
//@import '../fontawesome.min.css';

.row .col {
    + .explore-items {
        padding: 1em;
    }
    &.meta-detail,
    &.meta-item {
        padding: 0;
    }
}
.attribution {
    padding-bottom: 0.2em;
}

/* Modal Content/Box */
.modal {
    display: none;
    position: fixed;
    z-index: 10002;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-main {
    position: fixed;
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
    margin-left: auto;
    margin-right: auto;
    backface-visibility: hidden;
    filter: blur(0px);
    background: #efefef;
    border: 1px solid #888;
    color: #484459;
    //word-break: break-all;
    width: 100%;
    max-height: 100%;
    height: 100vh;
    overflow: scroll;

    /* top: 40%; */
    top: calc(-50vh + 50%);
    /* bottom: calc(-50vh + 60%) */
    /* TRANSFORM CAUSES FONT BLURRINESS */
    /* transform: perspective(1px) translate(-50%,-50%); */
}
.modal-main {
    padding: 2%;
}
.modal-text {
    margin: 0 0 0.5em 0;
}
.modal-btn {
    margin-right: 12px;
    cursor: pointer;
    padding: 0.4em;
    transition: background-color 300ms;
    text-transform: uppercase;
    i {
        padding-right: 0.4em;
    }
    &:focus {
        background-color: transparent;
    }
    &:hover {
        background-color: rgba($color: $c-gold-dark, $alpha: 0.2);
    }
}
.modal-title {
    font-size: 1.6em;
    font-family: 'Noto Serif', serif;
    //text-transform: uppercase;
    padding-bottom: 0.6em;
}
.spacer {
    padding: 0 0 0 1em;
}
.all-caps {
    text-transform: uppercase;
}
.no-trans {
    text-transform: none;
}
.meta-italics {
    font-style: italic;
}
.meta-item {
    margin: 0;
    white-space: pre-wrap;
}
.meta-grouping {
    padding-bottom: 1em;
    .meta-item {
        font-size: 1em;
    }
}
.with-fa {
    padding-bottom: 0.1em;
}
.with-fa-note {
    padding-bottom: 0.5em;
}
.with-fa::before,
.with-fa-note::before {
    font-family: 'Font Awesome 5 Pro';
    display: inline-block;
    width: 1.3em; /* same as padding-left set on li */
}
.with-fa::before {
    content: '\f007'; /*'\f101'; */
}
.with-fa-note::before {
    content: '\f15b';
}
.meta-catalog {
    font-size: 1em;
    word-break: normal;
}
.meta-title {
    font-weight: bold;
}
.meta-detail-list {
    font-style: italic;
    font-size: 0.8em;
    margin: 0 0 0.5em 0;
    list-style-type: none;
    padding-left: 0;
    padding-bottom: 1em;
}
.detail-data {
    background-color: #efefef;
    padding: 4%;
    border: $test-border; // 1px solid #222;
}

.details-abs-container {
    display: flex;
    justify-content: flex-start;
    //flex-basis: 70%;
    .back-btn {
        cursor: pointer;
        padding: 0 1em;
    }
}

.details-sections {
    + .keyboard-focused .collapsible-header:focus {
        background-color: transparent;
    }
    &.keyboard-focused .collapsible-header:focus {
        background-color: transparent;
    }
    .collapsible {
        .collapsible-header {
            &:hover {
                background-color: transparent;
            }
        }
    }
    .collapsible-header {
        background-color: transparent;
        border-bottom: 1px dashed rgba($color: $c-gold-dark, $alpha: 0.8);
        margin-bottom: 0;
    }
    .collapsible-body {
        margin-bottom: 2em;
    }
    .span-title {
        display: block;
        text-transform: none;
        padding: 0.3em;
        font-weight: normal;
        border-bottom: 1px dashed rgba($color: $c-gold, $alpha: 0.9);
        i {
            margin-right: 1em;
        }
        &:hover {
            background-color: rgba($color: $c-gold, $alpha: 0.3);
        }
    }
}

.details-container {
    width: 100%;
}
.detail-title {
    font-size: 1.6em;
    font-family: 'Noto Serif', serif;
}
.detail-top-section {
    margin-bottom: 1em;
    background-color: rgba($color: $c-gold, $alpha: 0.2);
    padding: 1em;
    letter-spacing: 0.02em;
}
.explore-items {
    background-color: rgba($color: $c-gold-dark, $alpha: 0.2);
    padding: 1em;
    margin: 0.2em auto;
    .title {
        text-transform: uppercase;
        font-weight: bold;
        border-bottom: 1px solid $c-gold-dark;
    }
}
.row {
    .col {
        &.bottom-items {
            padding: 0 1em 0 0;
            margin-left: 0;
        }
    }
}

/* MEDIA QUERIES
breakpoints: 1680, 1280, 980, 736, 480
*/
@media screen and (max-width: 1680px) {
    .modal-main {
        padding: 2%;
    }
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 980px) {
}
@media screen and (max-width: 736px) {
    .modal-main {
        padding: 2%;
    }
}

@media screen and (max-width: 480px) {
}
