@import './vars';

.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
}

.sidenav a:hover {
    color: #f1f1f1;
}
.sidenav input {
    color: #efefef;
}
.sidenav-sections {
    display: flex;
    flex-direction: column;
}
.sidenav-title {
    //font-family: 'Noto-Serif', serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 2em;
    line-height: 110%;
    margin: 1.5em 0 1em 0;
    //padding-bottom: 0.6em;
    padding: 1em 0.3em;
    font-weight: bold;
}
.side-nav {
    //border: $test-border;
    background-color: rgba($color: $c-light, $alpha: 0.1);
}
.meta-count {
    letter-spacing: 0.2em;
    margin: 0 0 1em 0;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.8em;
}
.collapsible {
    margin: 0;
    padding: 0;
    box-shadow: none;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    li.active {
        .collapsible-header {
            i {
                color: $c-purple-dark;
            }
        }
    }
    &:hover {
        // box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        //     0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0;
    }
    .collapsible-header {
        text-transform: uppercase;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-top: 1px solid transparent;
        border-bottom: 1px dashed rgba($color: $c-dark, $alpha: 0.4);
        background-color: $c-white;
        i {
            color: $c-purple-dark;
        }
        // &:hover {
        //     background-color: rgba($color: $c-gold, $alpha: 0.1);
        // }
    }
    .collapsible-body {
        padding: 0;
        border-bottom: none;
    }
}
.pad-it {
    margin: 1.2em 0.1em;
    border: 1px solid rgba($color: $c-purple-light, $alpha: 0.2);
}

.sidebar-text {
    font-size: 0.8em;
    text-transform: initial;
    font-style: italic;
    padding: 0.6em;
}
.sidebar-filter-text {
    font-size: 1em;
    padding: 0;
    margin: 0;
    font-style: normal;
    text-transform: uppercase;
}
.item-filter {
    //background-color: rgba($color: $c-white, $alpha: 0.6);
    transition: all 300ms;
    border-radius: 0 0 2px 2px;
    border-bottom: 1px dashed rgba($color: $c-purple-light, $alpha: 0.2);
    padding: 0.6em;
    margin-bottom: 0;
    //text-transform: uppercase;
    cursor: pointer;
    i {
        justify-content: flex-end;
        width: 10%;
    }
    div {
        width: 90%;
    }
    &:hover {
        //background-color: rgba($color: $c-gold-dark, $alpha: 0.4);
        border-bottom: 1px $c-gold dashed;
        background-color: rgba($color: $c-gold-dark, $alpha: 0.2);
        .fade-up {
            opacity: 1;
        }
    }
    &.active {
        background-color: rgba($color: $c-gold-dark, $alpha: 0.2);
        border-bottom: 1px $c-gold dashed;
    }
}
.current-filters {
    font-size: 0.8em;
    display: flex;
    p {
        background-color: $c-white;
        padding: 0.4em;
        transition: all 400ms ease-in-out;
        min-height: 2em;
        line-height: 1.2em;
        margin: 0 0.4em 0.4em 0;
        cursor: pointer;
        i {
            margin-left: 0.4em;
            order: 2;
            vertical-align: middle;
        }
        // &:last-child {
        //     margin: 0 0 1em;
        // }
        &:hover {
            opacity: 0.7;
        }
    }
}
.user-view {
    div {
        line-height: 1.1em;
        font-size: 1.1em;
        color: $c-purple;
        font-style: italic;
        margin: 0.3em;
    }
}

/* MEDIA QUERIES
  breakpoints: 1680, 1280, 980, 736, 480
  */
@media screen and (max-width: 1680px) {
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 980px) {
}

@media screen and (max-width: 736px) {
}

@media screen and (max-width: 601px) {
    .sidenav {
        width: 100%;
    }
    .side-nav {
        margin: 1.2em;
        padding: 0;
    }
}

@media screen and (max-width: 480px) {
}
